//IMAGE CARD

.full-image-card {
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  margin-bottom: 60px;
  @media (min-width: $desktop) {
    margin-bottom: 110px;
  }
}
