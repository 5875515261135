//CARDS

.card {
  border: none;
  border-radius: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  @media (min-width: $desktop) {
    margin-bottom: 110px;
  }
  &.card-reverse {
    @media (min-width: $desktop) {
      flex-direction: column-reverse;
      .card-body {
        margin-bottom: 110px;
      }
      img {
        margin-bottom: 0;
      }
    }
  }
  img {
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 60px;
  }
}

.card-body {
  padding: 0 20px 0 0;
  .card-title {
    margin-bottom: 24px;
    @media (min-width: $desktop) {
      margin-bottom: 56px;
    }
  }
}

.card-row {
  @media (min-width: $desktop) {
    flex-direction: row;
  }
  img {
    @media (min-width: $desktop) {
      flex-shrink: 0;
      width: 60%;
      margin-right: 5%;
    }
  }
  .card-body {
    @media (min-width: $desktop) {
      padding-bottom: 60px;
    }
  }
  &.card-reverse {
    @media (min-width: $desktop) {
      flex-direction: row-reverse;
    }
    img {
      @media (min-width: $desktop) {
        flex-shrink: 0;
        width: 60%;
        margin-right: 0;
        margin-left: 5%;
      }
    }
  }
}
