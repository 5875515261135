//FIXED NAVIGATION

.fixed-navigation {
  // background-color: #161718;
  position: sticky !important;
  margin-top: -60px;
  top: -3px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 88;
  overflow-y: auto;
  opacity: 0;
  display: none;
  transition: all 500ms 0s ease;
  @media (min-width: $desktop) {
    padding-top: 0;
    display: block !important;
    opacity: 1 !important;
  }
  .container-fluid {
    // background-color: rgb(39, 10, 10);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    @media (max-width: $desktop) {
      position: fixed;
      top: 0;
      right: 0;
      min-width: 100vw;
      min-height: 100vh;
    }

    @media (min-width: $desktop) {
      flex-direction: row;
      justify-content: center;
      height: 65px;
      padding-top: 0;
    }
  }
  .fixed-navigation-item {
    color: #fff;
    font-size: 26px;
    padding-bottom: 8px;
    margin-bottom: 20px;
    opacity: 0;
    transform: translateY(140px);
    position: relative;
    background: transparent;
    border: none;
    outline: none;
    &:last-child {
      margin-bottom: 0;
    }
    &:after {
      content: "";
      display: block;
      height: 2px;
      background-color: rgba(#fff, 0.5);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      transition: all 350ms 0s ease;
    }
    &.active {
      border-color: rgba(#fff, 0.3);
      &:after {
        width: 100%;
      }
    }
    &:active {
      opacity: 0.5;
    }
    &:hover {
      text-decoration: none;
    }
    @media (min-width: $desktop) {
      font-size: 13px;
      padding: 5px 0;
      opacity: 0.7 !important;
      letter-spacing: 1.2px;
      transition: all 350ms 0s ease;
      margin-right: 40px;
      margin-bottom: 0;
      transform: translateY(0) !important;
      &.active {
        opacity: 1 !important;
      }
      &:hover {
        opacity: 1 !important;
        &:after {
          width: 100%;
          background-color: #fff;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.fixed-navigation-mobile-handler {
  height: 60px;
  position: sticky;
  right: 0;
  top: -3px;
  left: 0;
  z-index: 97;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 500ms 0s ease;

  span {
    padding-bottom: 5px;
    position: relative;
    &:after {
      content: "";
      display: block;
      height: 2px;
      background-color: rgba(#fff, 0.5);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      transition: all 350ms 0s ease;
    }
  }
  > div {
    display: flex;
    background-color: #f2f2f2;
    color: #222;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 40px;
  }
  @media (min-width: $desktop) {
    display: none !important;
  }
}

.back-to-dev {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding-left: 30px;
  color: #fff;
  line-height: 20px;
  flex-direction: column;
  justify-content: center;
  transition: all 300ms 0s ease;
  border: none;
  outline: none;
  background: transparent;
  @media (min-width: $desktop) {
    display: flex;
  }
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    opacity: 0.6;
  }
}

.back-to-dev-link-title {
  font-size: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  .icon {
    font-size: 10px;
    margin-right: 5px;
    margin-top: -3px;
  }
}

.dev-name {
  font-weight: 600;
  font-size: 16px;
}

.starting-price {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding-right: 30px;
  line-height: 20px;
  display: none;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  @media (min-width: $desktop) {
    display: flex;
  }
  h6 {
    font-size: 12px;
    letter-spacing: 1px;
  }
}
.starting-price-info {
  font-weight: 600;
}

.fixed-navigation-closer {
  position: fixed;
  top: 40px;
  color: #fff;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  z-index: inherit;
  font-size: 18px;
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  left: 50%;
  margin-left: -25px;
  @media (min-width: $desktop) {
    display: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
