//VIDEO MODAL

.video-modal{
  position: fixed;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  video{
    max-width: 100%;
    position: relative;
    z-index: 1;
    @media (min-width: $desktop){
      width: 100%;
      max-width: 1400px;
    }
  }
}

.video-modal-close{
  font-size: 24px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  z-index: 2;
  @media (min-width: $desktop){
    transition: all 300ms 0s ease;
  }
  &:hover{
    color: #fff;
    @media (min-width: $desktop){
      opacity: 0.5;
    }
  }
}

.video-modal-bg-close{
  position: absolute;
  top:0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  z-index: 0;
}
