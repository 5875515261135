.swiper {
  &__image {
    width: 100%;
  }
  margin-bottom: 110px;
}
.vertical__image {
  object-fit: cover;
  height: 100%;
}
