//FORMS
// .form-control {
//   background-color: transparent;
//   height: $formElementHeight;
//   font-size: $formElementFontSize;
//   border-radius: $formElementRadius;
//   padding: $formElementPadding;
//   color: #fff;
//   border: 1px solid rgba(#fff, 0.25);
//   &:focus {
//     background-color: transparent;
//     color: #fff;
//     box-shadow: none;
//     outline: none;
//     border-color: #fff;
//   }
//   &::placeholder {
//     color: #fff;
//     opacity: 1; /* Firefox */
//   }
//   &:-ms-input-placeholder {
//     /* Internet Explorer 10-11 */
//     color: #fff;
//   }
//   &::-ms-input-placeholder {
//     /* Microsoft Edge */
//     color: #fff;
//   }
// }

.form-group {
  margin-bottom: 20px;
}

.custom-control-label {
  cursor: pointer;
}

//-> Custom Checkbox
.custom-checkbox {
  .custom-control-label::before,
  .custom-control-label::after {
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -11px;
    outline: none;
    box-shadow: none;
  }

  .custom-control-label::before {
    background-color: transparent;
    border-radius: 2px;
    outline: none;
    box-shadow: none;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #777;
  border-color: #777;
  outline: none;
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label::after {
  outline: none;
  box-shadow: none;
}

//-> Card Checkbox

.card-checkbox {
  .custom-checkbox {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    border-radius: 2px;
    overflow: hidden;
    margin-bottom: 15px;

    .custom-control-input {
      &:checked {
        ~ .custom-control-label {
          &:after {
            background: url('/assets/img/icons/check.svg') no-repeat 10px 10px;
          }
        }
      }
    }
  }

  .custom-control-label {
    &:after,
    &:before {
      border: none;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      left: 0;
      top: 0;
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  .icon {
    position: relative;
    z-index: 1;
    pointer-events: none;
    font-size: 29px;
    color: #fff;
  }

  label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: #27282a;
    z-index: 0;
    cursor: pointer;
  }

  h6 {
    text-align: center;
    font-size: 10px;
    letter-spacing: 2px;
    margin-bottom: 0;
  }
}

//-> Box Checkbox

.box-checkbox {
  display: inline-block;
  .custom-checkbox {
    height: 37px;
    background: #27282a;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    margin-right: 5px;
  }

  .checkbox-bedrooms {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  p {
    position: relative;
    z-index: 1;
    pointer-events: none;
    margin: 0;
    padding-top: 3px;
    letter-spacing: 2px;
    font-size: 10px;
    text-align: center;
    line-height: 12px;
    width: 100%;
  }

  .custom-control-label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
  }

  .custom-checkbox .custom-control-label::before,
  .custom-checkbox .custom-control-label::after {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    border: none;
    z-index: 0;
  }
  .custom-control-input {
    &:checked {
      ~ .custom-control-label {
        &:after {
          background: url('/assets/img/icons/check.svg') no-repeat 8px center;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

//->Box Radio

.box-radio {
  .custom-control {
    position: relative;
    height: 37px;
    padding: 0 25px;
    @media (min-width: $desktop) {
      height: 34px;
      padding: 0 17px;
    }
    p {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      z-index: 10;
      margin: 0;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      pointer-events: none;
    }
  }
  .custom-control-label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    &:before,
    &:after {
      left: 0;
      top: 0;
      bottom: 0;
      margin: 0;
      border: none;
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
    &:before {
      background-color: #27282a;
    }
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
  }
}

//-> Image Radio

#language-selector {
  display: flex;
  justify-content: center;
  .image-radio {
    margin-right: 24px;
    .custom-control-label {
      text-indent: -99999px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.custom-control-input {
  z-index: 1;
  width: 4rem;
  height: 4rem;
}

.image-radio {
  position: relative;
  width: 34px;
  height: 24px;
  .custom-radio {
    padding: 0;
    .custom-control-input:checked ~ .custom-control-label::after {
      border-color: rgba(#fff, 0.5);
      background: none;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background: none;
    }
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .custom-control-label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:before,
    &:after {
      position: absolute;
      top: -8px;
      left: -8px;
      right: -8px;
      bottom: -8px;
      margin: 0;
      background: none;
      width: auto;
      height: auto;
      border-radius: 2px;
      border: 1px solid transparent;
    }
  }
}

//->Currency Select

#currency-select {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}
