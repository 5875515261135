//INFORMATIONS

.informations{
  display: flex;
  justify-content: center;
  padding: 0 30px;
  margin-bottom: 100px;
  @media (min-width: $desktop){
    margin-bottom: 130px;
    .full-image-card+&{
      margin-top: -50px;
    }
  }
}

.information-item{
  text-align: center;
  margin-right: 30px;
  @media (min-width: $desktop){
    margin-right: 120px;
  }
  &:last-child{
    margin-right: 0;
  }
  h6{
    margin-bottom: 0;
    @media (min-width: $desktop){
      font-size: 18px;
    }
  }
}
.information-value{
  font-size: 30px;
  font-style: italic;
  @media (min-width: $desktop){
    font-size: 50px;
    line-height: 1.6;
  }
  span{
    font-size: 16px;
    @media (min-width: $desktop){
      font-size: 18px;
    }
  }
}
