.notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  background: url('/assets/img/promo/3.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  h3 {
    margin-top: 4rem;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
}
