//HERO VIDEO

.hero-video {
  height: 380px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 60px;
  @media (min-width: $desktop) {
    height: 620px;
    margin-bottom: 270px;
  }
  @media (min-width: $largeDesktop) {
    height: 840px;
    margin-bottom: 320px;
  }

  &.no-video-image {
    height: auto;
    background-image: none;
    margin-bottom: 60px;
    @media (min-width: $desktop) {
      margin-bottom: 110px;
    }
  }

  .container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .video-modal-handler {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #eee;
    }
    @media (min-width: $desktop) {
      &:hover {
        .icon {
          opacity: 0.5;
        }
      }
    }

    .icon {
      font-size: 40px;
      color: #fff;
      @media (min-width: $desktop) {
        font-size: 90px;
        transition: all 250ms 0s ease;
      }
    }
  }
}

.hero-video-holder {
  position: relative;
  height: 235px;
  margin-bottom: -20px;
  .no-video-image & {
    margin-bottom: 0;
  }
  @media (min-width: $desktop) {
    height: 500px;
    margin-bottom: -150px;
  }
  @media (min-width: $largeDesktop) {
    height: 720px;
    margin-bottom: -180px;
  }

  video {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    background: url('/assets/img/promo-loader.gif') no-repeat center;
    background-size: 50px auto;
  }
}
