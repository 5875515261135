//REBOOT
// @font-face {
//   font-family: 'site-font';
//   src: url('/assets/fonts/reg.woff2');
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'site-font';
//   src: url('/assets/fonts/reg-italic.woff2');
//   font-style: italic;
//   font-weight: 400;
// }

// @font-face {
//   font-family: 'site-font';
//   src: url('/assets/fonts/bold.woff2');
//   font-weight: 700;
// }
html,
body {
  height: 100%;
  touch-action: pan-y;
}
a {
  text-decoration: none;
}
body {
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: $black;
  background-color: #1d273c;
  font-size: 12px !important;
  line-height: 1.8;
  // overflow: hidden;
  @media (min-width: $desktop) {
    font-size: 16px !important;
    line-height: 2.2;
  }
}

.container-fluid {
  @media (min-width: $desktop) {
    padding-left: 7.5%;
    padding-right: 7.5%;
  }
}

.icon {
  display: inline-block;
  font-size: 24px;
  stroke: currentColor;
  fill: currentColor;
}

.main-content {
  padding-bottom: 1px;
}

.row {
  @media (min-width: $desktop) {
    margin-left: -4.5%;
    margin-right: -4.5%;
  }
}

[class^="col-"] {
  @media (min-width: $desktop) {
    padding-left: 4.5%;
    padding-right: 4.5%;
  }
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

canvas {
  /*width: 800px;*/
  /*height: 600px;*/
  width: 100%;
  overflow-x: hidden;
  background-color: #1e4875;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.hidden {
  display: none;
}

.fp-slides {
  // z-index: 3 !important;
}
