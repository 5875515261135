/* TourviewApp.css */

@font-face {
  font-family: 'site-font';
  src: url('/public/assets/fonts/reg.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'site-font';
  src: url('/public/assets/fonts/reg-italic.woff2');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'site-font';
  src: url('/public/assets/fonts/bold.woff2');
  font-weight: 700;
}

body {
  font-family: 'site-font', "Montserrat" !important;
  background-color: #000 !important;
  color: #fff;
}
.App {
  text-align: center;
}

.tourview path {
  opacity: 0.5;
  stroke: #ffffff;
}

.tourview path:hover {
  opacity: 0.9;
  stroke: white;
}

.tourview path:focus {
  opacity: 0.9;
  stroke: white;
  stroke-width: 2px;
}

.bedroom-button {
  width: 40px;
  height: 40px;
  border-radius: 20px !important;
  border: 1px solid #fff !important;
  background-color: #00000050 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  margin-right: 10px;
  font-size: 16px !important;
}

.bedroom-button:hover {
  background-color: #000 !important;
}

.bedroom-button:checked,
.btn-check:checked + .btn-primary {
  background-color: #fff !important;
  color: #000 !important;
}

.slider-handles {
  width: 40px;
  height: 40px;
  border-radius: 20px !important;
  background-color: #fff;
  display: flex !important;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  font-size: 16px !important;
}
.slider-handles:focus-visible {
  outline: #fff;
}
h5 {
  color: #fff;
  font-size: 1rem !important;
}

.clear-button,
.rotate-button {
  border-radius: 20px !important;
  border: 1px solid #fff !important;
  background-color: #00000050 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  font-size: 16px !important;
  align-self: center;
  margin-top: 25px;
  margin-left: 10px;
}

.facilities-button {
  border-radius: 20px !important;
  border: 1px solid #fff !important;
  background-color: #00000050 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  font-size: 16px !important;
  align-self: center;
  margin-right: 10px;
}

.filter-container {
  /* display: flex; */
  justify-content: center;
  padding-top: 20px;
}
@media only screen and (max-height: 400px) {
  .filter-container {
    padding-top: 10px;
    text-align: center;
  }
  .clear-button {
    margin-top: 0;
    margin-left: 0;
    display: inline-block !important;
  }
}

.rotate-button {
  position: absolute;
  top: 40%;
}

.promo-video {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  background-size: 50px auto;
}

.filter-instagram{
  right: 0;
}

/**** Hover Popup ****/

@media only screen and (max-height: 400px) {
  .hover_popup.active {
    font-size: 9px;
  }
  .hover_popup .data-wrap > div {
    margin-bottom: 5px !important;
  }
  .hover_popup:after {
    bottom: 50% !important;
    right: -15px !important;
    left: auto !important;
  }
}
.hover_popup {
  position: absolute;
  z-index: 20;
  max-height: 0;
  width: 192px;
  opacity: 0;
  transform: translate(-50%, -100%);
  margin-left: -2px;
  margin-top: -25px;
  transition: opacity 0.5s ease 0.3s;
  background-color: rgba(255, 255, 255, 1);
  /* border-radius: 5px; */
  overflow: hidden;
}
.hover_popup_instagram:after{
  display: none !important;
}
.building_active .hover_popup {
  width: 276px;
}

.hover_popup.bottom {
  transform: translate(-50%, 0);
  margin-top: 25px;
}

.hover_popup:after {
  display: block;
  content: "";
  height: 17px;
  width: 17px;
  transform: rotate(45deg) translate(-50%, 0);
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  bottom: -14px;
  left: 50%;
  z-index: 5;
}

.hover_popup.bottom:after {
  top: 0;
  bottom: auto;
  margin-top: -2px;
}

.hover_popup.active {
  opacity: 1;
  max-height: 400px;
  overflow: visible;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.4);
  z-index: 200;
}

.hover_popup .hover_popup_content {
  color: #59595b;
  overflow: hidden;
  z-index: 10;
  position: relative;
}

.hover_popup .data-wrap {
  margin: 15px 0;
  padding: 0 15px;
  border-bottom: 1px solid rgba(41, 41, 41, 0.18);
  margin-bottom: 0;
}

.hover_popup .data-wrap > div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.hover_popup .data-wrap div > label {
  font-weight: 900;
}

.building_active .hover_popup .data-wrap {
  display: block;
}

.hover_popup .button-wrap {
  padding: 0 5px;
  margin-bottom: 6px;
  z-index: 10;
  position: relative;
}

.hover_popup .button-wrap .enter {
  display: block;
  width: 100%;
  background-color: transparent;
  padding: 6px 11px;
  margin: 4px 0;
  cursor: pointer;
  background: url(/public/assets/img/chevron-right.svg) no-repeat center right transparent;
  background-size: 24px;
}

.hover_popup .button-wrap .enter label {
  display: flex;
  font-family: Optima-Regular;
  color: #14171c;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.hover_popup .button-wrap .enter label.building_title {
  font-size: 16px;
}

.hover_popup .button-wrap .enter label.enter_label {
  border: 0 !important;
}

.building_active .hover_popup .button-wrap .enter label.enter_label {
  display: flex;
  font-size: 18px;
  justify-content: center;
}

.hover_popup .button-wrap .close {
  display: block;
  height: 0;
  width: 0;
  padding: 0;
  overflow: hidden;
}

.hover_popup .hover_apt_name {
  color: #000;
  font-size: 16px;
  margin: 10px 10px;
  text-align: center;
  display: none;
}

.hover_popup .hover_popup_img {
  margin: 0px;
}

.building_active .hover_popup .hover_popup_img {
  display: none;
}

.hover_popup .hover_popup_img img {
  width: 100%;
}

.nav {
  position: fixed;
  top: calc(50vh - 22px);
  z-index: 25;
  -webkit-touch-callout: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 22px;
  background: url("") no-repeat center / 25px rgba(0, 0, 0, 0.5);
}

.nav i {
  display: none;
}

.nav.hidden {
  display: none;
}

.nav:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 1);
}

.nav.nav_left {
  left: 10px;
  background-image: url('/assets/img/rotate-angle-left.svg');
}

.sidebar_active .nav.nav_left {
  left: 380px;
}

.nav.nav_right {
  right: 10px;
  background-image: url('/assets/img/rotate-angle-right.svg');
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  transition: all 0.4s ease 0s;
  overflow: hidden;
  text-align: center;
}

.rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  cursor: pointer;
  transition: all 0.4s ease 0s;
  overflow: hidden;
  text-align: center;
  background-image: url(/public/assets/img/rectangle.svg);
}

/* ROTATE DEVICE
------------------------------------------- */

.pull {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
}

.overlay,
.overlay-up {
  display: none;
  position: absolute;
  top: 0;
  background-color: #000;
  width: 100%;
  height: 100%;
  z-index: 30;
  justify-content: center;
  text-align: center;
}

@media only screen and (orientation: landscape) {
  .iconContainer {
    top: 25%;
  }
}

@media only screen and (orientation: portrait) {
  .iconContainer {
    top: 25%;
  }
}

.iconContainer {
  position: absolute;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
}
.iconContainer p {
  display: inline-block;
  width: 80%;
  text-align: center;
  margin: 0;
  font-size: 20px;
  color: #fff !important;
  opacity: 1;
}

@media only screen and (max-width: 1020px) {
  .overlay {
    display: flex;
  }
}


.fa-facebook{
  color: #3B5998;
}

.fa-google{
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

.fa-instagram{
  color: transparent;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
}

.fa-linkedin {
  color: #2867B2;
}

.fa-twitter {
  color: #1DA1F2;
}

.fa-facebook,.fa-google,.fa-instagram,.fa-linkedin,.fa-twitter {
  font-size: 20px !important;
}

.ReactModal__Overlay {
  z-index: 100;
}


.left-arrow {
  animation-name: left-arrow;
  animation-duration: 2s;
  animation-iteration-count: 4;
}

@keyframes left-arrow {
  0%   {left:80px; opacity: 1;}
  50%  {left:120px; opacity: 1;}
  100% {left:80px; opacity: 1;}
}

.right-arrow {
  animation-name: right-arrow;
  animation-duration: 2s;
  animation-iteration-count: 4;
}

@keyframes right-arrow {
  0%   {right:80px; opacity: 1;}
  50%  {right:120px; opacity: 1;}
  100% {right:80px; opacity: 1;}
}


#vrview .zsiq_floatmain{
  display: none;
}

.zsiq_cnt {
  display: none !important;
}