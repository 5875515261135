//CAROUSEL

.carousel{
  margin-bottom: 50px;
  @media (min-width: $desktop) {
    margin-bottom: 160px;
  }
  .swiper-slide{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    img{
      display: block;
      width: 100%;
      height: auto;
      min-height: 300px;
      @media (min-width: $desktop) {
        min-height: 0;
      }
    }
  }
}
