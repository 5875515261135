.icon {
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.filter-item .icon-angle-up {
  font-size: 0.1rem;
  transform: rotate(180deg);
}
