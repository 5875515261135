//GET IN TOUCH BUTTON

#fixed-get-in-touch-button {
  display: block;
  @media (min-width: $desktop) {
    display: none !important;
  }
}

#get-in-touch-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  background: transparent;
  margin: 0;
  border: none;
  color: #fff;
  padding: 20px;
  letter-spacing: 1.2px;
  outline: none;
  display: none;
  &:focus {
    outline: none;
  }
  img {
    margin-left: 10px;
  }
  @media (min-width: $desktop) {
    display: none;
    pointer-events: auto;
  }
}

#desktop-get-in-touch-action {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  pointer-events: visible;
  display: none;
  @media (min-width: $desktop) {
    pointer-events: auto;
    display: block;
  }

  .icon {
    font-size: 50px;
    margin-left: 10px;
  }

  &:after {
    content: '';
    position: fixed;
    bottom: 0;
    right: 0;
    width: 400px;
    height: 400px;
    background-image: linear-gradient(-45deg, #000000 0%, rgba(0, 0, 0, 0) 50%);
    z-index: 1;
    pointer-events: none;
    opacity: 0.8;
  }
}
