.slick-slider {
  margin-bottom: 60px;
  @media (min-width: $desktop) {
    margin-bottom: 110px;
  }
}
.slick-slide {
  img {
    width: 100%;
  }
}

.slick-dots {
  bottom: 20px;
}
.slick-dots li.slick-active button:before {
  font-size: 1rem;
  border: 1px solid #fff;
  color: transparent !important;
  border-radius: 100%;
  background-color: transparent;
}
.slick-next:before,
.slick-prev:before {
  display: none !important;
}
.slick-next {
  @media (min-width: $desktop) {
    padding: 1.5rem;
  }
  padding: 1rem;
  border-radius: 100%;
  margin-left: 1rem;
  border: 1px solid #fff;
}
.slick-prev {
  border-radius: 100%;
  border: 1px solid #fff;
  @media (min-width: $desktop) {
    padding: 1.5rem;
  }
  padding: 1rem;
}
.sliderright {
  font-size: 1rem !important;
  margin-top: -0.4rem;
  margin-left: -0.1rem;
}
.sliderleft {
  font-size: 1rem !important;
  margin-top: -0.4rem;
  margin-left: -0.3rem;
}
