//SELECT

.select2-container {
  display: block;
  //width: auto !important;
  &:focus {
    outline: none;
  }
  .select2-selection {
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid rgba(#fff, 0.25);
    height: $formElementHeight;
    &:focus {
      outline: none;
    }
    .select2-selection__rendered {
      line-height: $formElementHeight;
      color: #fff;
      font-size: 14px;
      text-align: left;
      padding-right: 30px;
    }
    .select2-selection__arrow {
      height: $formElementHeight;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      b {
        display: block;
        border: none;
        margin: 0;
        width: 10px;
        height: 5px;
        position: static;
        background: url("/assets/img/angle-down-white.svg") no-repeat center /
          contain;
      }
    }
  }
  .select2-results__option--highlighted[aria-selected],
  .select2-results__option[aria-selected="true"] {
    background-color: #777;
  }
}

.select2-dropdown {
  background-color: #27282a;
  border-color: rgba(#fff, 0.25);
  font-size: 13px;
  &.no-border-dropdown {
    border: none;
    background-color: transparent;
    .select2-results__option {
      padding: 3px 10px 1px 10px;
      background-color: $black;
      border-radius: 2px;
      transition: all 250ms 0s ease;
      @media (min-width: $desktop) {
        background-color: rgba($black, 0.2);
        margin-bottom: 2px;
      }
      &:hover {
        background-color: rgba($black, 0.4);
      }
    }
  }
}

//NO BORDER

.select-box-no-border-wrapper {
  margin-bottom: 10px;
  @media (min-width: $desktop) {
    margin-bottom: 0;
  }
  + .select-box-no-border-wrapper {
    @media (min-width: $desktop) {
      margin-left: 20px;
    }
  }
  .select2-container {
    .select2-selection {
      border: none;
      height: auto;
      .select2-selection__rendered {
        line-height: normal;
        padding-left: 0;
        padding-top: 3px;
        letter-spacing: 1px;
        font-size: 12px;
      }
      .select2-selection__arrow {
        height: 100%;
        b {
          margin-top: -2px;
        }
      }
    }
  }
}
.select2-search {
  padding: 10px;
  .select2-search__field {
    font-size: 16px;
    border: none;
    height: 36px;
    padding: 0 15px;
    border-radius: 2px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
