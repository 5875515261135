//GALLERY

.gallery{
  position: relative;
  margin-bottom: 60px;
  @media (min-width: $desktop){
    margin-bottom: 110px;
  }
  .carousel{
    @media (min-width: $desktop){
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      align-items: center;
      justify-content: center;
      z-index: 99;
    }
  }
  &.show-gallery{
    .carousel{
      @media (min-width: $desktop){
        display: flex;
      }
    }
  }
}

.gallery-slides{
  display: none;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  @media (min-width: $desktop){
    display: flex;
  }
}
.gallery-slides-part{
  height: 100%;
  flex: 1;
  display: flex;
  &.gallery-column{
    flex-direction: column-reverse;
  }
}
.gallery-slide{
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.gallery-slides-holder{
  display: none;
  max-width: 100%;
  height: auto;
  @media (min-width: $desktop){
    display: block;
  }
}

.gallery-carousel-close{
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 30px;
  &:hover{
    color: #fff;
  }
}
