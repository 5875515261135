//VARIABLES

//----- Colors ----->
$black: #161718;


//----- BreakPoints ----->

$mobile: 576px;
$tablet: 768px;
$desktop: 992px;
$largeDesktop: 1600px;


//----- Sizes ----->

$formElementHeight: 42px;
$formElementFontSize: 16px;
$formElementPadding: 0 16px;
$formElementRadius: 2px;
