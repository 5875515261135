//DESKTOP SEARCH

#desktop-search {
  background-color: rgba($black, 0.7);
  position: fixed !important;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  display: none;
  transition: all 250ms 0s ease;
  @media (min-width: $desktop) {
    display: block;
  }
  .container-fluid {
    display: flex;
    height: 70px;
  }
  &.hide {
    bottom: -70px !important;
  }
}

#desktop-search-action-buttons {
  display: flex;
  padding-left: 20px;
  height: 100%;
  align-items: center;
  .btn {
    width: 160px;
    flex-shrink: 0;
    margin: 0 20px 0 0;
    &:last-child {
      margin-right: 0;
    }
  }
}

.filter-item {
  height: 100%;
  border-right: 1px solid rgba(#fff, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex: 1;
  &.active {
    background-color: $black;
  }
  &:first-child {
    border-left: 1px solid rgba(#fff, 0.15);
  }
  .icon-angle-up {
    position: absolute;
    font-size: 6px;
    top: 50%;
    margin-top: -3px;
    right: 20px;
  }
  a {
    display: block;
    color: #fff;
    padding-top: 3px;
    padding-right: 50px;
    padding-left: 20px;
    &:focus,
    &:hover {
      outline: none;
      text-decoration: none;
      color: #fff;
    }
  }
}

.filter-title {
  font-size: 10px;
  letter-spacing: 1px;
  margin-bottom: -4px;
  white-space: nowrap;
}

.filter-value {
  font-size: 14px;
  letter-spacing: 1px;
}

.filter-detail {
  position: absolute;
  min-width: 500px;
  border: 1px solid rgba(#fff, 0.2);
  background-color: $black;
  bottom: 100%;
  left: -1px;
  border-radius: 2px;
  margin-bottom: -1px;
  padding: 30px 20px;
  z-index: -1;
  display: none;
  .active & {
    display: block;
  }
}
.filter-detail-footer {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(#fff, 0.1);
  padding-top: 20px;
  margin-top: 20px;
}

#desktop-filter-lifestyle {
  display: flex;
  flex-wrap: wrap;
  .card-checkbox {
    flex: 1;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

#desktop-filter-property-type {
  display: flex;
  .box-checkbox {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
