h1,h2,h3,h4,h5,h6{
  font-weight: 400;
}
h1{
  font-size: 44px;
}
h2{
  font-size: 38px;
  @media (min-width: $desktop){
    font-size: 65px;
  }
}
h3{
  font-size: 24px;
  @media (min-width: $desktop){
    font-size: 34px;
  }
}
h4{
  font-size: 18px;
  @media (min-width: $desktop){
    font-size: 24px;
  }
}
h6{
  font-size: 14px;
  @media (min-width: $desktop){
    font-size: 16px;
  }
}

b{
  font-weight: 600;
}
